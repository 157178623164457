import React from 'react';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';

interface Props {
  seoProps: PageContent.SeoType;
  langProps: PageContent.PageLanguageProps;
}

export default function SeoAndSchema({ seoProps, langProps }: Props) {
  return (
    <>
      <Seo
        {...{
          title: seoProps.seoMetaTitle,
          description: seoProps.seoMetaDescription,
          keywords: seoProps.seoMetaKeywords,
          externalHreflangs: seoProps.seoExternalHreflangs,
          ogImage: seoProps.ogImage,
          lang: langProps.lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoProps.seoMetaTitle}
        data={{
          metaTitle: seoProps.seoMetaTitle,
          metaDescription: seoProps.seoMetaDescription,
          metaKeywords: seoProps.seoMetaKeywords,
        }}
      />
    </>
  );
}
