// THIS SITE WAS CREATED FOR VEET PK
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import SeoAndSchema from 'templates/SeoAndSchema/SeoAndSchema';
import { SimplePagePageProps } from './models';
import './SimplePagePage.scss';

const SimplePagePage: FC<SimplePagePageProps> = ({
  data: {
    allUmbracoSimplePage: {
      nodes: [{ langProps, body, banner, seoProps }],
    },
  },
}) => {
  return (
    <Layout langProps={langProps} className="simplePage-page">
      <SeoAndSchema seoProps={seoProps} langProps={langProps} />
      {banner && <SimplePageBanner banner={banner} />}

      {body && (
        <div className="bodySection">
          {body.map((e, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SimplePageBody key={index} body={e} />
          ))}
        </div>
      )}
    </Layout>
  );
};

function SimplePageBanner({ banner }: { banner: SimplePageTypes.SimplePageBannerType }) {
  return (
    <div className="banner">
      <div className="banner__inner">
        {banner.title && <h1 className="banner__title">{banner.title}</h1>}
      </div>
    </div>
  );
}

function SimplePageBody({ body }: { body: SimplePageTypes.SimplePageBodyType }) {
  return (
    <div className="body">
      {body.text && <div className="body__text" dangerouslySetInnerHTML={{ __html: body.text }} />}
      {body.accordion && <SimplePageAccordion items={body.accordion} />}
    </div>
  );
}

function SimplePageAccordion({ items }: { items: SimplePageTypes.AccordionType[] }) {
  return (
    <div className="accordion">
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SimplePageAccordionItem key={index} item={item} />
      ))}
    </div>
  );
}

function SimplePageAccordionItem({ item }: { item: SimplePageTypes.AccordionType }) {
  return (
    <a href={item.link?.url} className="accordion__item">
      {item.label}
    </a>
  );
}

// query SimplePageQuery($pageId: String, $lang: String) {
export const query = graphql`
  query SimplePageQuery($pageId: String) {
    allUmbracoSimplePage(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        body {
          text
          accordion {
            label
            link {
              url
            }
          }
        }
        banner {
          description
          image
          title
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
      }
    }
  }
`;

export default SimplePagePage;
